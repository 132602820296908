<script lang="ts">
  import Button from '../button/button.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import Input from '@temporalio/ui/holocene/input/input.svelte';
  import Textarea from '@temporalio/ui/holocene/textarea.svelte';
  import Select from '@temporalio/ui/holocene/select/select.svelte';
  import { countries } from '$lib/utilities/countries';
  import Option from '@temporalio/ui/holocene/select/option.svelte';
  import Checkbox from '@temporalio/ui/holocene/checkbox.svelte';
  import Text from '../text/text.svelte';
  import type { MarketoForm } from '$lib/contentful/models/marketo-form';
  import Icon from '../icon/icon.svelte';
  import type { AskAnExpertFields } from '$lib/services/marketo';

  export let handleSubmit: (values: AskAnExpertFields) => void;
  export let loading: boolean;
  export let form: MarketoForm;

  $: ({ title, body, entityId, anchor } = form);

  const values: AskAnExpertFields = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    country: '',
    Contact_Us_Reason__c: '',
    Contact_Us_Reason_Detail__c: '',
    Contact_Us_SDK__c: '',
    GDPR_Consent__c: false,
  };

  const howCanWeHelpOptions = [
    'Get started with Temporal',
    'Request a demo',
    'Workflow design and best practices',
    'Performance tuning Temporal',
    'Other',
  ];

  const sdkOptions = ['Go', 'Python', 'Java', 'TypeScript', 'PHP', '.NET'];
</script>

<Grid id={anchor} as="div" layout="columns">
  <Box class="flex flex-col gap-4" span={4} as="div">
    <Text fieldId="title" {entityId} variant="display-68" as="h3">{title}</Text>
    <div class="flex flex-col gap-4">
      <Text fieldId="body" {entityId} variant="heading-32" as="p">{body}</Text>
      <Text class="text-indigo-100" variant="body-16" as="p">
        Please fill out the required information and submit the form. A member
        of our team will get in touch with you shortly.
      </Text>
    </div>
  </Box>
  <Box span={4} offset={8} as="div">
    <form
      class="flex flex-col gap-4"
      on:submit|preventDefault={() => {
        handleSubmit(values);
      }}
    >
      <Input label="First name" id="first-name" bind:value={values.firstName} />
      <Input label="Last name" id="last-name" bind:value={values.lastName} />
      <Input
        label="Email address"
        id="email-address"
        bind:value={values.email}
      />
      <Input label="Company" id="company" bind:value={values.company} />
      <Select label="Country" id="country" bind:value={values.country}>
        {#each countries as option}
          <Option value={option}>{option}</Option>
        {/each}
      </Select>
      <Select
        label="How can we help?"
        id="reason"
        bind:value={values.Contact_Us_Reason__c}
      >
        {#each howCanWeHelpOptions as option}
          <Option value={option}>{option}</Option>
        {/each}
      </Select>
      <Textarea
        label="Additional details"
        id="reason-details"
        bind:value={values.Contact_Us_Reason_Detail__c}
      />
      <Select
        label="Primary SDK"
        id="sdk"
        bind:value={values.Contact_Us_SDK__c}
      >
        {#each sdkOptions as option}
          <Option value={option}>{option}</Option>
        {/each}
      </Select>
      <Checkbox
        label="Email opt-in"
        id="email-opt-in"
        bind:checked={values.GDPR_Consent__c}
      />
      <p class="text-xs">
        By submitting this form, you are sharing your information with Temporal
        Technologies and agree to our <a
          class="pulsar-link"
          target="_blank"
          href="https://docs.temporal.io/privacy-policy/">Privacy Policy</a
        >.
      </p>
      <Button type="submit">
        {#if loading}
          <Icon name="spinner" class="animate-spin" />
        {:else}
          Submit request
        {/if}
      </Button>
    </form>
    <slot />
  </Box>
</Grid>
