<script lang="ts">
  import type { MarketoForm } from '$lib/contentful/models/marketo-form';
  import Text from '$lib/components/text/text.svelte';
  import { twMerge } from 'tailwind-merge';
  import Icon from '../icon/icon.svelte';

  let email = '';
  export let loading: boolean;
  export let handleSubmit: (fields: Record<string, string>) => void;
  export let form: MarketoForm;

  $: ({ formId, title, background, anchor } = form);
</script>

<div id={anchor} class={twMerge('newsletter-signup', $$props.class)}>
  {#if title}
    <Text as="p" variant="mono-16">{title}</Text>
  {/if}
  <form
    on:submit|preventDefault={() => handleSubmit({ email })}
    class={background}
  >
    <label class="sr-only" for={`${formId}`}>Email Address</label>
    <input
      bind:value={email}
      type="email"
      data-lpignore
      data-1p-ignore
      placeholder="Email Address"
      id={`${formId}`}
    />
    {#if background === 'light'}
      <button class="light" disabled={loading} type="submit">
        {#if loading}
          <Icon name="spinner" class="animate-spin" />
        {:else}
          Subscribe
        {/if}
      </button>
    {:else}
      <button class="dark" disabled={loading} type="submit">
        <Icon
          name={loading ? 'spinner' : 'paper-plane'}
          class={loading ? 'animate-spin' : undefined}
        />
      </button>
    {/if}
  </form>
  <slot />
</div>

<style lang="postcss">
  .newsletter-signup {
    @apply flex flex-col gap-3;
  }

  form {
    @apply flex rounded-lg sm:focus-within:ring-4 focus-within:ring-ultraviolet/70 flex-row sm:gap-0 max-sm:gap-2;

    &.dark {
      @apply gap-[2px];
    }
  }

  input {
    @apply placeholder:text-blue-gray-300 placeholder:uppercase text-space-black font-mono rounded-l-lg max-sm:placeholder:text-center py-4 px-6 grow focus:outline-none max-sm:focus:ring-4 max-sm:focus:ring-ultraviolet/70;
  }

  button.light {
    @apply bg-space-black text-white rounded-r-lg py-4 px-6 font-mono hover:bg-deep-blue disabled:pointer-events-none;
  }

  button.dark {
    @apply bg-gradient-ultraviolet disabled:bg-gradient-ultraviolet-disabled text-white p-4 rounded-r-lg;
  }
</style>
